import {
  FilterIcon,
  Healthy,
  Mexican,
  NoRestaurants,
  Pleasers,
  SandWich,
  Sushi,
  Thai,
} from 'assets/img';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { StoreContext } from 'pages/_app';
import { useCallback, useContext, useEffect, useState } from 'react';
import { changeSpacesToHyphens, debounce } from 'utils/helpers/restaurants';
import { getFiltersQuery, isPresent } from 'utils/helpers/array';
import styles from './Directory.module.scss';
import AddressTimeModal from 'components/AddressTimeModal';
import QueryString from 'qs';
import {
  cuisineFilters,
  dietaryFilters,
  featuredFilters,
  packagingFilters,
} from 'utils/constants/filters';
import { getUpdatedFilterValue } from 'utils/helpers/string';
import MobileDateTimeModal from 'components/MobileDateTimeModal/MobileDateTimeModal';
import pluralize from 'pluralize';
import Head from 'next/head';
const FiltersModal = dynamic(() => import('components/FiltersModal'));
const Filters = dynamic(() => import('components/Filters/Filters'));
const RestaurantDetailsModal = dynamic(() =>
  import('components/RestaurantDetailsModal/RestaurantDetailsModal')
);
const SearchBar = dynamic(() => import('components/SearchBar/SearchBar'));
const RestaurantCard = dynamic(() => import('components/RestaurantCard/RestaurantCard'));
import LearnMoreBadge from 'components/GroupOrder/LearnMore/LearnMoreBadge';
import StartOrderingModal from 'components/GroupOrder/StartOrderingModal/StartOrderingModal';
import FilterOptions from './FilterOptions';
import Ilustration from './Ilustration';
import ExpandRadiusCheckbox from './ExpandRadiusCheckBox';
import SortBySelect from './SortBySelect';

const DESCRIPTION =
  "Find local catering from your city's best restaurants delivered to your office, workplace or corporate event.";

function Home() {
  const [filtersModal, setFiltersModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [menuDetails, setMenuDetails] = useState(null);
  const [isExpandedRadius, setExpandedRadius] = useState(false);
  const [addressTimeModal, setAddressTimeModal] = useState(false);
  const [startGroupOrder, setStartGroupOrder] = useState(false);
  const [initialFiltersApplied, setApplied] = useState(false);
  const [loadingMenu, setLoadingMenu] = useState(false);
  const [loadedMenu, setLoadedMenu] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loadComponents, setLoadComponents] = useState(false);

  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);

  const router = useRouter();
  let addressQuery = router?.query?.address;
  let dateQuery = router?.query?.date;
  let timeQuery = router?.query?.time;

  let { allMenus, allMenusPagy, address, sortBy, allFilters, isLoading, startGroupOrderShown } =
    userStore;

  useEffect(() => {
    if (!router?.isReady) return;

    setLoadComponents(true);

    store.userStore?.getSettings();

    getAvailableDates({
      month: dateQuery ? moment(dateQuery).month() + 1 : moment().month() + 1,
      year: dateQuery ? moment(dateQuery).year() : moment().year(),
    });

    if (!dateQuery || !timeQuery || !isFutureDate()) {
      store?.userStore?.setDate(null);
      store?.userStore?.setTime(null);
    }

    setAddressTimeModal(true);
  }, [router?.isReady]);

  useEffect(() => {
    if (!router?.isReady || !store?.userStore?.isHydrated) return;

    store.userStore?.getAndSetTimezone(addressQuery);

    getAvailableDates({
      month: dateQuery ? moment(dateQuery).month() + 1 : moment().month() + 1,
      year: dateQuery ? moment(dateQuery).year() : moment().year(),
    });
  }, [addressQuery]);

  useEffect(() => {
    if (!router?.isReady) return;

    setTimeout(() => {
      if (dateQuery && isFutureDate()) store?.userStore?.setDate(moment(dateQuery));

      if (timeQuery && isFutureDate()) store?.userStore?.setTime(timeQuery);

      if (addressQuery) {
        store?.userStore?.setAddressOnly(addressQuery);
      }
    }, 100);
  }, [router?.isReady, timeQuery, dateQuery, addressQuery]);

  useEffect(() => {
    if (!router?.isReady || !store?.userStore?.isHydrated) return;

    if (dateQuery) {
      getAvailableTimes({ selected_date: dateQuery });
    }
  }, [dateQuery, addressQuery]);

  const isFutureDate = () => {
    return (
      dateQuery &&
      timeQuery &&
      moment(
        `${moment(dateQuery).format('YYYY-MM-DD')} ${timeQuery}`,
        'YYYY-MM-DD h:mm a'
      ).isSameOrAfter(moment())
    );
  };

  const getAvailableDates = params => {
    store.userStore.getAvailableDates({
      ...params,
      address: addressQuery,
      current_date_time: moment().format('YYYY-MM-DD HH:mm'),
    });
  };

  const getAvailableTimes = params => {
    store.userStore.getAvailableTimes({
      ...params,
      address: addressQuery,
      current_date_time: moment().format('YYYY-MM-DD HH:mm'),
    });
  };

  const featuredBadges = () => {
    let featuredChosenTags = Array.isArray(router?.query?.featured)
      ? router?.query?.featured
      : router?.query?.featured
      ? [router?.query?.featured]
      : [];

    return featuredChosenTags;
  };

  const chosenFeaturedTags = () => {
    let featuredChosenTags = Array.isArray(router?.query?.featured)
      ? router?.query?.featured
      : router?.query?.featured
      ? [router?.query?.featured]
      : [];

    if (featuredChosenTags?.length) {
      const allFilterOptionsChildern = {
        ...allFilters?.featured_badges,
      };

      const newFeaturedList = featuredChosenTags?.reduce((acc, curr) => {
        if (allFilterOptionsChildern[curr]) acc = [...acc, ...allFilterOptionsChildern[curr]];
        return acc;
      }, []);

      return newFeaturedList;
    }

    return [];
  };

  const choosenTags = () => {
    let cuisineChosenTags = Array.isArray(router?.query?.cuisine)
      ? router?.query?.cuisine
      : router?.query?.cuisine
      ? [router?.query?.cuisine]
      : [];

    let dietaryChosenTags = Array.isArray(router?.query?.dietary)
      ? router?.query?.dietary
      : router?.query?.dietary
      ? [router?.query?.dietary]
      : [];

    let packagingChosenTags = Array.isArray(router?.query?.packaging)
      ? router?.query?.packaging
      : router?.query?.packaging
      ? [router?.query?.packaging]
      : [];

    if (dietaryChosenTags?.length || cuisineChosenTags?.length || packagingChosenTags?.length) {
      const allFilterOptionsChildern = {
        ...allFilters?.cuisines,
        ...allFilters?.dietary_preferences,
        ...allFilters?.packagings,
      };

      const newDietaryList = dietaryChosenTags?.reduce((acc, curr) => {
        if (allFilterOptionsChildern[curr]) acc = [...acc, ...allFilterOptionsChildern[curr]];
        return acc;
      }, []);

      const newCuisineList = cuisineChosenTags?.reduce((acc, curr) => {
        if (allFilterOptionsChildern[curr]) acc = [...acc, ...allFilterOptionsChildern[curr]];
        return acc;
      }, []);

      const newPackagingList = packagingChosenTags?.reduce((acc, curr) => {
        if (allFilterOptionsChildern[curr]) acc = [...acc, ...allFilterOptionsChildern[curr]];
        return acc;
      }, []);

      return newDietaryList.concat(newCuisineList).concat(newPackagingList);
    }

    return [];
  };

  useEffect(() => {
    if (store?.userStore?.isHydrated && initialFiltersApplied) {
      const abortController = new AbortController();

      const fetchData = () => {
        getMenus(1, 30, abortController.signal);
      };

      fetchData();
      setSearchText(router?.query?.search ?? '');
      return () => {
        abortController.abort();
      };
    }
  }, [
    store?.userStore?.isHydrated,
    choosenTags()?.length,
    featuredBadges()?.length,
    router?.query,
    initialFiltersApplied,
    isExpandedRadius,
  ]);

  const getAllQuery = (filterType, value) => {
    value = getUpdatedFilterValue(value);

    let query = { ...router?.query };
    delete query.search;

    const handleFilter = filterKey => {
      let filterValues = getFiltersQuery(query[filterKey]) || [];

      if (query[filterKey]?.includes(value)) {
        filterValues = filterValues.filter(f => f !== value);
      } else {
        filterValues = [value, ...filterValues];
      }

      return filterValues.length
        ? { ...query, [filterKey]: filterValues }
        : { ...query, [filterKey]: [] };
    };

    switch (filterType) {
      case 'cuisine':
        return handleFilter('cuisine');

      case 'dietary':
        return handleFilter('dietary');

      case 'packaging':
        return handleFilter('packaging');

      case 'featured':
        return handleFilter('featured');

      case 'reset':
        delete query.cuisine;
        delete query.dietary;
        delete query.packaging;
        delete query.featured;
        return query;

      default:
        return query;
    }
  };

  const updateChosenRestaurantFilters = async (filterType, e) => {
    router.replace(
      {
        pathname: '/',
        query: getAllQuery(filterType, e?.target?.value),
      },
      undefined, //By passing undefined as the second argument, it indicates that no specific as value is being provided. In other words, the new URL being replaced will have the same path and query parameters as the original URL.
      { shallow: true, scroll: false }
    );
  };

  const getMenus = (page = 1, radius = 30, signal, sortVal) => {
    let sortValue = sortVal ?? sortBy?.value;

    if (!isFutureDate()) {
      return;
    }

    setTimeout(() => {
      store.userStore.setLoader(true);
    }, 200);

    let payload = {
      radius: isExpandedRadius ? 50 : radius,
      page,
      per_page: 21,
      address: addressQuery || address,
      search_q: router?.query?.search,
      sort_by: sortValue,
      current_date_time: moment().format('YYYY-MM-DD HH:mm'),
      tags: choosenTags(),
      featured_badges: chosenFeaturedTags(),
    };

    const date = dateQuery;

    payload.date = date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

    payload.time = timeQuery;

    return store.userStore?.getLocationMenus(payload, signal).then(response => {
      store?.userStore.setLoader(false);

      const hasErrorMessage =
        response?.message && !response?.data && response?.message !== 'canceled';

      if (hasErrorMessage) store.userStore.setErrorAlert(true, { title: response?.message });
    });
  };

  const loadMore = () => {
    getMenus(allMenusPagy?.next);
  };

  const removeSearchFromQuery = useCallback(() => {
    let query = QueryString.parse(router?.query);
    delete query['search'];
    router.replace({ pathname: '/', query: { ...QueryString.parse(query) } }, undefined, {
      shallow: true,
    });
  });

  const handleOnChange = debounce((e, page) => {
    store.userStore.setLoader(true);

    setSearchText(e?.target?.value);

    if (e?.target?.value == '') {
      removeSearchFromQuery();
    } else
      router.replace(
        {
          pathname: '/',
          query: { ...router?.query, search: e?.target?.value },
        },
        undefined,
        { shallow: true }
      );
  }, 1000);

  const handleOnBlur = debounce(e => {
    if (e?.target?.value === '' && router?.query?.search) {
      store.userStore.setLoader(true);
      setSearchText(e?.target?.value);
      removeSearchFromQuery();
    }
  }, 1000);

  const onChangeSortBy = useCallback(value => {
    store?.userStore.setSort(value);

    getMenus(1, 30, null, value?.value);
  });

  const expandRadius = useCallback(() => {
    setExpandedRadius(true);
  });

  const clearAll = useCallback(() => {
    updateChosenRestaurantFilters('reset', null, true);
  });

  const loadTagsForMenu = id => {
    setLoadingMenu(true);
    store?.restaurantStore
      ?.getMenuPreview(id, {
        time: timeQuery,
        address: addressQuery,
        current_date_time: moment().format('YYYY-MM-DD HH:mm'),
      })
      .then(response => {
        setLoadedMenu(response?.data);
        setLoadingMenu(false);
      });
  };

  return (
    <>
      <Head>
        <title>Catering by hungerhub</title>
        <meta name="description" content={DESCRIPTION} key="desc" />
        <meta property="og:title" content="Catering by hungerhub" />
        <meta property="og:description" content={DESCRIPTION} />
      </Head>

      <div className="flex flex-col bg-white min-h-screen mx-auto" style={{ maxWidth: '1440px' }}>
        <Filters getAvailableDates={getAvailableDates} getAvailableTimes={getAvailableTimes} />

        <div className="flex flex-col md:p-4 pb-0 md:px-8 bg-white" style={{ maxWidth: '1440px' }}>
          <div className="flex flex-row w-full">
            <div className="hidden md:flex md:w-2/12">
              <div className="sticky-side-section">
                <div className="flex flex-col">
                  <div className="flex items-center justify-between">
                    <span className="text-xl text-primary-black font-inter-medium">Featured</span>

                    {(isPresent(choosenTags()) || isPresent(featuredBadges())) && (
                      <button
                        onClick={() => clearAll()}
                        className="flex btn-style font-inter-medium text-sm text-hh-accent-light hover:text-hh-accent-dark">
                        Clear all
                      </button>
                    )}
                  </div>

                  <div className="flex flex-col mt-2 mb-4">
                    <FilterOptions
                      filterType="featured"
                      filters={featuredFilters}
                      updateChosenRestaurantFilters={updateChosenRestaurantFilters}
                      router={router}
                    />
                  </div>

                  <div className="flex items-center justify-between">
                    <span className="text-xl text-primary-black font-inter-medium">Cuisine</span>
                  </div>

                  <div className="flex flex-col">
                    <FilterOptions
                      filterType="cuisine"
                      filters={cuisineFilters?.filter((cuisine, idx) => idx < 10)}
                      updateChosenRestaurantFilters={updateChosenRestaurantFilters}
                      router={router}
                    />

                    <button
                      onClick={() => setFiltersModal(true)}
                      className="flex btn-style underline font-inter-medium text-primary-dark mt-2 hover:text-primary-black">
                      Show All
                    </button>
                  </div>

                  <span className="text-xl text-primary-black font-inter-medium mt-8">Dietary</span>

                  <div className="flex flex-col">
                    <FilterOptions
                      filterType="dietary"
                      filters={dietaryFilters}
                      updateChosenRestaurantFilters={updateChosenRestaurantFilters}
                      router={router}
                    />
                  </div>

                  <span className="text-xl text-primary-black font-inter-medium mt-8">
                    Packaging
                  </span>

                  <div className="flex flex-col">
                    <FilterOptions
                      filterType="packaging"
                      filters={packagingFilters}
                      updateChosenRestaurantFilters={updateChosenRestaurantFilters}
                      router={router}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col flex-wrap w-full">
              <div className="hidden md:flex flex-row flex-wrap justify-between mr-[5px]">
                <Ilustration
                  width={80}
                  height={69}
                  bgImage={Healthy}
                  text={'Healthy'}
                  updateChosenRestaurantFilters={updateChosenRestaurantFilters}
                />
                <Ilustration
                  width={80}
                  height={66}
                  bgImage={SandWich}
                  text={'Sandwiches'}
                  updateChosenRestaurantFilters={updateChosenRestaurantFilters}
                />

                <div
                  onClick={() =>
                    updateChosenRestaurantFilters(
                      'featured',
                      {
                        target: {
                          value: 'Crowd Pleasers',
                        },
                      },
                      true
                    )
                  }
                  className={`flex cursor-pointer ${styles.cuisineCard} justify-center relative  bg-background ml-3 my-2 rounded-lg`}>
                  <div className={`${styles.crowd}`}>
                    <Image src={Pleasers} alt="pleasers" />
                  </div>

                  <span
                    className={`text-sm ${styles.captionText} font-inter-medium flex-wrap break-words`}
                    style={{ position: 'absolute', bottom: 8 }}>
                    {'Crowd Pleasers'}
                  </span>
                </div>

                <Ilustration
                  width={81}
                  height={66}
                  bgImage={Thai}
                  text={'Thai'}
                  updateChosenRestaurantFilters={updateChosenRestaurantFilters}
                />
                <Ilustration
                  width={80}
                  height={66}
                  bgImage={Mexican}
                  text={'Mexican'}
                  updateChosenRestaurantFilters={updateChosenRestaurantFilters}
                />
                <Ilustration
                  width={80}
                  height={66}
                  bgImage={Sushi}
                  text={'Sushi'}
                  updateChosenRestaurantFilters={updateChosenRestaurantFilters}
                />
              </div>

              {/* Tablet */}
              <div className="hidden sm:px-4 sm:pb-4 customMd:flex md:hidden sticky top-[154px] min-[731px]:top-[144px] z-10 bg-white">
                <div className="flex w-full justify-between items-center mt-3 md:mt-10 ">
                  <div className="flex flex-col w-full">
                    <div className="flex w-full justify-between">
                      <button
                        onClick={() => setFiltersModal(true)}
                        className="flex btn-style p-3 w-32 px-5 items-center justify-center rounded-lg bg-background">
                        <Image src={FilterIcon} width={15} height={12} alt="filter-icon" />

                        <span className="text-primary-black text-md ml-3">Filters</span>
                      </button>

                      <div className={`w-3/5 mx-3`}>
                        <SearchBar
                          key={searchText}
                          handleOnChange={handleOnChange}
                          handleOnBlur={handleOnBlur}
                          value={searchText}
                        />
                      </div>

                      <SortBySelect sortBy={sortBy} onChange={onChangeSortBy} />
                    </div>

                    <div className="mt-4">
                      {allMenus?.length == 0 && (
                        <ExpandRadiusCheckbox
                          setExpandedRadius={setExpandedRadius}
                          isExpandedRadius={isExpandedRadius}
                        />
                      )}
                    </div>

                    <span className="mt-7 text-[24px] font-inter-semibold">
                      {allMenusPagy?.count} {pluralize('restaurant', allMenusPagy?.count)}
                    </span>
                  </div>
                </div>
              </div>

              {/* Mobile */}
              <div className="flex p-4 customMd:hidden sticky top-[80px] bg-white z-10 md:hidden">
                <div className="flex w-full justify-between items-center">
                  <div className="flex flex-col w-full">
                    <div className="flex w-full justify-between">
                      <button
                        onClick={() => setFiltersModal(true)}
                        className="flex btn-style p-3 px-5 mr-5 w-1/2 items-center justify-center rounded-lg bg-background">
                        <Image src={FilterIcon} width={15} height={12} alt="filter-icon-mobile" />
                        <span className="text-primary-black text-md ml-3">Filters</span>
                      </button>

                      <div className="w-1/2">
                        <SortBySelect sortBy={sortBy} onChange={onChangeSortBy} />
                      </div>
                    </div>

                    <div className="mt-4">
                      {allMenus?.length == 0 && (
                        <ExpandRadiusCheckbox
                          setExpandedRadius={setExpandedRadius}
                          isExpandedRadius={isExpandedRadius}
                        />
                      )}
                    </div>

                    <span className="mt-7 text-[24px] font-inter-semibold">
                      {allMenusPagy?.count} {pluralize('restaurant', allMenusPagy?.count)}
                    </span>
                  </div>
                </div>
              </div>

              {/* Desktop */}
              <div className="hidden md:flex md:w-full items-center md:sticky md:top-12 z-10 bg-white">
                <div className="flex w-full justify-between items-center mt-3 md:pt-10 md:pb-5 ml-3">
                  <span className="text-[24px] font-inter-semibold">
                    {allMenusPagy?.count} {pluralize('restaurant', allMenusPagy?.count)}
                  </span>

                  <div className="flex mr-[5px]">
                    {allMenus?.length == 0 && (
                      <ExpandRadiusCheckbox
                        setExpandedRadius={setExpandedRadius}
                        isExpandedRadius={isExpandedRadius}
                      />
                    )}

                    <SearchBar
                      handleOnChange={handleOnChange}
                      handleOnBlur={handleOnBlur}
                      value={searchText}
                    />

                    <SortBySelect sortBy={sortBy} onChange={onChangeSortBy} />
                  </div>
                </div>
              </div>

              {!startGroupOrderShown && store?.userStore?.isHydrated && (
                <LearnMoreBadge setStartGroupOrder={setStartGroupOrder} />
              )}

              {allMenus?.length > 0 ? (
                <div className="cards-grid">
                  {allMenus.map((menu, index) => (
                    <RestaurantCard
                      currentImageIndex={currentImageIndex}
                      setCurrentImageIndex={setCurrentImageIndex}
                      loadTagsForMenu={loadTagsForMenu}
                      setPreviewModal={setPreviewModal}
                      setMenuDetails={setMenuDetails}
                      key={`card-${index}`}
                      index={index}
                      menu={menu}
                    />
                  ))}
                </div>
              ) : (
                !isLoading && (
                  <div className="flex flex-col justify-center items-center mt-20">
                    <Image src={NoRestaurants} width={170} height={170} alt="no-restaurants" />

                    <div className="flex flex-col justify-center">
                      <span className="text-sm text-center text-primary-black mt-3">
                        No restaurants are available to deliver to this address.
                      </span>

                      {!isExpandedRadius && (
                        <button className="mt-3 btn-style" onClick={expandRadius}>
                          <span className="text-primary-dark">
                            Try expanding your delivery radius to 50 km
                          </span>
                        </button>
                      )}

                      <div
                        className={`flex ${
                          isExpandedRadius
                            ? 'flex-row items-center justify-center mt-3'
                            : 'flex-col'
                        }`}>
                        <button
                          onClick={() => {
                            setAddressTimeModal(true);
                          }}
                          className={`${isExpandedRadius ? 'mt-0' : 'mt-3'} btn-style`}>
                          <span className="text-primary-dark">Change address</span>
                        </button>

                        {isExpandedRadius && (
                          <span className="text-sm text-primary-black mx-2">or</span>
                        )}

                        <button className="mt-3">
                          <a
                            href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/bring-hungerhub-to-your-city`}
                            className={`${isExpandedRadius ? 'mt-0' : 'mt-3'} btn-style`}>
                            <span className="text-primary-dark">
                              Request hungerhub in your city
                            </span>
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                )
              )}

              {allMenusPagy?.next && (
                <div className="flex items-center justify-center my-2" onClick={loadMore}>
                  <button className="btn-purple">Load more restaurants</button>
                </div>
              )}
            </div>
          </div>
        </div>

        {loadComponents && <FiltersModal visible={filtersModal} setModal={setFiltersModal} />}

        {loadComponents && (
          <RestaurantDetailsModal
            visible={previewModal}
            closeModal={() => {
              setPreviewModal(false);
              setTimeout(() => setMenuDetails(null), 300);
            }}
            loadingMenu={loadingMenu}
            loadedMenu={loadedMenu}
            menu={menuDetails}
          />
        )}

        {addressTimeModal && (
          <AddressTimeModal
            getAvailableTimes={getAvailableTimes}
            getAvailableDates={getAvailableDates}
            visible={addressTimeModal}
            setApplied={setApplied}
            setModal={value => {
              setAddressTimeModal(value);
            }}
          />
        )}

        <MobileDateTimeModal
          getAvailableDates={getAvailableDates}
          getAvailableTimes={getAvailableTimes}
        />

        {startGroupOrder && (
          <StartOrderingModal visible={startGroupOrder} setModal={setStartGroupOrder} />
        )}
      </div>
    </>
  );
}

export default observer(Home);
